import { DecodedToken } from './interfaces';
import { convertKeysToCamelCase } from '@app/classes/camelize';

export const checkTokenValidity = (token: string): boolean => {
  const decodedToken: DecodedToken = decodeToken(token);

  if (decodedToken) {
    const expirationDate: Date = new Date(decodedToken.exp * 1000);

    return expirationDate > new Date();
  }

  return false;
};

export const extractUserId = (token: string): string => {
  const decodedToken: DecodedToken = convertKeysToCamelCase(decodeToken(token)) as DecodedToken;

  return decodedToken.accountId;
};

export const decodeToken = (token: string): DecodedToken | null => {
  try {
    return JSON.parse(atob(token.split('.')[1])) as DecodedToken;
  } catch (e) {
    return null;
  }
};
