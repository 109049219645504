export enum AppRoutes {
  Dashboard = 'dashboard',
  Login = 'login',
  SignUp = 'signup',
  PasswordReset = 'password-reset',
  Invite = 'invite',
  Payment = 'payment',
  Auth = 'auth',
}

export enum DashboardRoutes {
  Overview = 'overview',
  Company = 'company',
}
