import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { selectAuthState } from '@app/shared/store/user/user.reducer';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router,
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.select(selectAuthState).pipe(
      map(({ access }): boolean => {
        if (!access) {
          void this.router.navigate(['/login']);

          return false;
        }

        return true;
      }),
    );
  }
}
