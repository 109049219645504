import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/shared/store/user/utils/auth-guard';
import { AppRoutes } from '@app/app.routes';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/client/client.module').then((m) => m.ClientPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.Auth,
    redirectTo: 'login',
  },
  {
    path: AppRoutes.Login,
    loadChildren: () => import('./pages/auth/children/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: AppRoutes.SignUp,
    loadChildren: () => import('./pages/auth/children/singup/singup.module').then((m) => m.SingupPageModule),
  },
  {
    path: AppRoutes.PasswordReset,
    loadChildren: () => import('./pages/auth/children/password-reset/password-reset.module').then((m) => m.PasswordResetPageModule),
  },
  {
    path: AppRoutes.Invite,
    loadChildren: () => import('./pages/auth/children/invite/invite.module').then((m) => m.InviteModule),
  },

  {
    path: AppRoutes.Payment,
    loadChildren: () => import('./pages/client/children/payment/payment.module').then((m) => m.PaymentPageModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
