/**
 * Bunkai API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: test@bunkai.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SubscriptionPlanPrices { 
    readonly id?: string;
    readonly isActive?: boolean;
    price: string;
    recurrence?: SubscriptionPlanPrices.RecurrenceEnum;
}
export namespace SubscriptionPlanPrices {
    export type RecurrenceEnum = 'MONTHLY' | 'THREE_MONTHS' | 'ANNUAL';
    export const RecurrenceEnum = {
        Monthly: 'MONTHLY' as RecurrenceEnum,
        ThreeMonths: 'THREE_MONTHS' as RecurrenceEnum,
        Annual: 'ANNUAL' as RecurrenceEnum
    };
}


