/**
 * Bunkai API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: test@bunkai.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface VPNSession { 
    bytesReceived: number;
    bytesSent: number;
    endedAt?: string | null;
    readonly id?: string;
    readonly realIpAddress?: string;
    startedAt: string;
    readonly virtualIpAddress?: string;
    vpn: string;
}

